export const github = (
  <svg
    width="43"
    height="37"
    viewBox="0 0 43 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M34.0417 0H8.95833C4.01154 0 0 3.45179 0 7.70833V29.2917C0 33.5482 4.01154 37 8.95833 37H34.0417C38.9902 37 43 33.5482 43 29.2917V7.70833C43 3.45179 38.9902 0 34.0417 0ZM26.0401 30.2012C25.3145 30.3215 25.0833 29.9376 25.0833 29.6092V26.2253C25.0833 25.0737 24.6139 24.3244 24.0979 23.9421C27.2907 23.6368 30.6447 22.5931 30.6447 17.8571C30.6447 16.5097 30.0857 15.409 29.1701 14.5472C29.317 14.2357 29.808 12.9808 29.0286 11.2835C29.0286 11.2835 27.8264 10.952 25.0905 12.5476C23.9438 12.2701 22.7183 12.136 21.5 12.1298C20.2817 12.1345 19.0562 12.2701 17.9113 12.5445C15.1736 10.9489 13.9696 11.2804 13.9696 11.2804C13.192 12.9793 13.683 14.2327 13.8317 14.5456C12.9143 15.409 12.3553 16.5082 12.3553 17.8556C12.3553 22.5792 15.7004 23.6384 18.886 23.9513C18.4757 24.2597 18.1048 24.8023 17.9758 25.6009C17.157 25.9154 15.084 26.4596 13.8048 24.5742C13.8048 24.5742 13.0469 23.3902 11.6064 23.3023C11.6064 23.3023 10.2089 23.2869 11.5079 24.0531C11.5079 24.0531 12.4485 24.4323 13.1007 25.8568C13.1007 25.8568 13.9302 28.0583 17.9167 27.3122V29.6077C17.9167 29.933 17.6855 30.3153 16.9707 30.2012C11.2732 28.5717 7.16667 23.9482 7.16667 18.5C7.16667 11.6874 13.5844 6.16667 21.5 6.16667C29.4156 6.16667 35.8333 11.6874 35.8333 18.5C35.8333 23.9467 31.7322 28.5686 26.0401 30.2012V30.2012Z" />
  </svg>
);

export const linkedin = (
  <svg
    width="43"
    height="37"
    viewBox="0 0 43 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M34.0417 0H8.95833C4.01154 0 0 3.45179 0 7.70833V29.2917C0 33.5482 4.01154 37 8.95833 37H34.0417C38.9902 37 43 33.5482 43 29.2917V7.70833C43 3.45179 38.9902 0 34.0417 0ZM14.3333 29.2917H8.95833V12.3333H14.3333V29.2917ZM11.6458 10.3785C9.91508 10.3785 8.51042 9.16058 8.51042 7.659C8.51042 6.15742 9.91508 4.9395 11.6458 4.9395C13.3766 4.9395 14.7812 6.15742 14.7812 7.659C14.7812 9.16058 13.3784 10.3785 11.6458 10.3785ZM35.8333 29.2917H30.4583V20.6522C30.4583 15.4598 23.2917 15.853 23.2917 20.6522V29.2917H17.9167V12.3333H23.2917V15.0544C25.7928 11.0676 35.8333 10.7732 35.8333 18.8715V29.2917Z" />
  </svg>
);
